// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var box__rObLf = "PlasmicFooter-module--box__rObLf--3Cemd";
export var box__yaYdS = "PlasmicFooter-module--box__yaYdS--F8Fig";
export var box__dux1V = "PlasmicFooter-module--box__dux1V--WUejU";
export var iconLink__exmMt = "PlasmicFooter-module--iconLink__exmMt--RSRGA";
export var svg__o9Lt9 = "PlasmicFooter-module--svg__o9Lt9--1Y1gX";
export var box__gfp5T = "PlasmicFooter-module--box__gfp5T--3kCKb";
export var link___90Sdz = "PlasmicFooter-module--link___90Sdz--2xRW0";
export var box__msYzL = "PlasmicFooter-module--box__msYzL--3Sico";
export var link___7X4Ds = "PlasmicFooter-module--link___7X4Ds--J8W9U";
export var box__zxyj4 = "PlasmicFooter-module--box__zxyj4--35BkS";
export var box__uu3WO = "PlasmicFooter-module--box__uu3WO--BHqkA";
export var box__qANwR = "PlasmicFooter-module--box__qANwR--bVt9t";
export var link__jJhId = "PlasmicFooter-module--link__jJhId--11-AD";
export var link__rEzeN = "PlasmicFooter-module--link__rEzeN--3KPP7";
export var link__gAjij = "PlasmicFooter-module--link__gAjij--3Cwqk";
export var link__zg8Vm = "PlasmicFooter-module--link__zg8Vm--1Pjjs";
export var link__mrExJ = "PlasmicFooter-module--link__mrExJ--2royb";
export var box__s3Ub = "PlasmicFooter-module--box__s3Ub--3YN05";
export var box___8HubW = "PlasmicFooter-module--box___8HubW--3boKx";
export var link__rtJzO = "PlasmicFooter-module--link__rtJzO--3FWd4";
export var link__j2Kg = "PlasmicFooter-module--link__j2Kg--1xTV7";
export var link__hYXhO = "PlasmicFooter-module--link__hYXhO--1bZaI";
export var link__klbCt = "PlasmicFooter-module--link__klbCt--22yXq";
export var link___7NaLo = "PlasmicFooter-module--link___7NaLo--1-iE8";
export var box__jIvLo = "PlasmicFooter-module--box__jIvLo--h3NZW";
export var box__laIxN = "PlasmicFooter-module--box__laIxN--3PTZs";
export var link__nDuX = "PlasmicFooter-module--link__nDuX--1EtXA";
export var link__bnEv3 = "PlasmicFooter-module--link__bnEv3--2k__e";
export var link__sucEe = "PlasmicFooter-module--link__sucEe--3iHPL";
export var link__fcCAf = "PlasmicFooter-module--link__fcCAf--1CQZe";
export var link__w6Xav = "PlasmicFooter-module--link__w6Xav--31rzA";
export var box___2Kx5X = "PlasmicFooter-module--box___2Kx5X--2ipGM";
export var box__bKw = "PlasmicFooter-module--box__bKw--1Bp0L";
export var box__uwJ1 = "PlasmicFooter-module--box__uwJ1--1eDNb";
export var box__jSnSo = "PlasmicFooter-module--box__jSnSo--yL8dJ";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var box__ps5E4 = "PlasmicFooter-module--box__ps5E4--2PKvF";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var svg___2KYkP = "PlasmicFooter-module--svg___2KYkP--cu2WB";
export var box__vXtj5 = "PlasmicFooter-module--box__vXtj5--1Ee8I";
export var box__xSUcT = "PlasmicFooter-module--box__xSUcT--2qas-";
export var box__bkXz6 = "PlasmicFooter-module--box__bkXz6--oFKOM";
export var link___574PF = "PlasmicFooter-module--link___574PF--2H8qv";
export var link__z9RBt = "PlasmicFooter-module--link__z9RBt--3DUvQ";
export var box__uZbeY = "PlasmicFooter-module--box__uZbeY--2CgKS";
export var box__oRtro = "PlasmicFooter-module--box__oRtro--xW4yg";
export var iconLink__uyKjy = "PlasmicFooter-module--iconLink__uyKjy--2yhne";
export var svg__g3Dg9 = "PlasmicFooter-module--svg__g3Dg9--2HRiP";
export var iconLink__hhozp = "PlasmicFooter-module--iconLink__hhozp--3H41F";
export var svg___60Rfp = "PlasmicFooter-module--svg___60Rfp--2wviR";
export var iconLink__tvHt = "PlasmicFooter-module--iconLink__tvHt--2Hmha";
export var svg__zElWg = "PlasmicFooter-module--svg__zElWg--1JMCO";