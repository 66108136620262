// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var box___8Fxpi = "PlasmicTopSection-module--box___8Fxpi--2NJho";
export var box__krCvm = "PlasmicTopSection-module--box__krCvm--2UuZu";
export var box__dLmA = "PlasmicTopSection-module--box__dLmA--1rTnS";
export var box__soAji = "PlasmicTopSection-module--box__soAji--1lgJC";
export var box__rl4KB = "PlasmicTopSection-module--box__rl4KB--3CRFy";
export var box__frPlP = "PlasmicTopSection-module--box__frPlP--1zGC0";
export var box__aS229 = "PlasmicTopSection-module--box__aS229--2Y4Ku";
export var linkButton__gHmwe = "PlasmicTopSection-module--linkButton__gHmwe--1B_vm";
export var linkButton__mDgRz = "PlasmicTopSection-module--linkButton__mDgRz--18Ah6";
export var box__lPtB = "PlasmicTopSection-module--box__lPtB--YYBi8";
export var img = "PlasmicTopSection-module--img--TX3Qd";