// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var box__qd98 = "PlasmicFeatureCard-module--box__qd98--SLzQ7";
export var box__lQvXm = "PlasmicFeatureCard-module--box__lQvXm--2nA_v";
export var svg__b9Fyy = "PlasmicFeatureCard-module--svg__b9Fyy--QiqMF";
export var box__ltb4 = "PlasmicFeatureCard-module--box__ltb4--1BbIZ";
export var box__long__ltb4XiqIa = "PlasmicFeatureCard-module--box__long__ltb4XiqIa--Av0L2";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var box__jhD4 = "PlasmicFeatureCard-module--box__jhD4--2dlsd";
export var box__long__jhD4XiqIa = "PlasmicFeatureCard-module--box__long__jhD4XiqIa--1qwaB";
export var svg__opjur = "PlasmicFeatureCard-module--svg__opjur--2YqL5";