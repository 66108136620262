// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var box__wDEut = "PlasmicPlan-module--box__wDEut--36oIf";
export var box__vHwc0 = "PlasmicPlan-module--box__vHwc0--ITRn9";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var box__fiwbS = "PlasmicPlan-module--box__fiwbS--12Php";
export var box__lll6D = "PlasmicPlan-module--box__lll6D--2vlMZ";
export var bullet__wqpcK = "PlasmicPlan-module--bullet__wqpcK--1EECP";
export var bullet__vrzaA = "PlasmicPlan-module--bullet__vrzaA--25-5l";
export var bullet__om8GJ = "PlasmicPlan-module--bullet__om8GJ--1ei3z";
export var box___30Yuy = "PlasmicPlan-module--box___30Yuy--1_0qL";
export var svg__vvOaf = "PlasmicPlan-module--svg__vvOaf--16OrB";