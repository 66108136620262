// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var box__jjb66 = "PlasmicPricing-module--box__jjb66--1Xhww";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section__vgsil = "PlasmicPricing-module--section__vgsil--19bpZ";
export var box__u6UdK = "PlasmicPricing-module--box__u6UdK--1iW6X";
export var plan__wo2Vh = "PlasmicPricing-module--plan__wo2Vh--2uIXO";
export var box__hd65L = "PlasmicPricing-module--box__hd65L--OlpcT";
export var box__goSEd = "PlasmicPricing-module--box__goSEd--3MfCK";
export var bullet___9ZvDm = "PlasmicPricing-module--bullet___9ZvDm--1WSJx";
export var bullet__sPqeA = "PlasmicPricing-module--bullet__sPqeA--1z4r-";
export var bullet__rtfP2 = "PlasmicPricing-module--bullet__rtfP2--3LP7y";
export var plan__wsqEz = "PlasmicPricing-module--plan__wsqEz--1CnZo";
export var box__sJaad = "PlasmicPricing-module--box__sJaad--qiSQ5";
export var box__rYxki = "PlasmicPricing-module--box__rYxki--Yw3DE";
export var bullet__nzChu = "PlasmicPricing-module--bullet__nzChu--1uihy";
export var bullet__wlnVw = "PlasmicPricing-module--bullet__wlnVw--2_IND";
export var bullet___1BVsU = "PlasmicPricing-module--bullet___1BVsU--1gDJJ";
export var bullet__yicqP = "PlasmicPricing-module--bullet__yicqP--1uN3h";
export var plan__em1Gm = "PlasmicPricing-module--plan__em1Gm--Zwhpz";
export var box__jeKMu = "PlasmicPricing-module--box__jeKMu--2V3Hd";
export var box__bUvT7 = "PlasmicPricing-module--box__bUvT7--vZwgh";
export var bullet__fTQpT = "PlasmicPricing-module--bullet__fTQpT--1jZlp";
export var bullet__mqQwt = "PlasmicPricing-module--bullet__mqQwt--2a39v";
export var bullet__gtCx = "PlasmicPricing-module--bullet__gtCx--3YNWX";
export var section___5IX = "PlasmicPricing-module--section___5IX--2DORt";
export var faq__qqG4 = "PlasmicPricing-module--faq__qqG4--1_XIC";
export var faq__ti8Z = "PlasmicPricing-module--faq__ti8Z--2RDTN";
export var faq__avXvj = "PlasmicPricing-module--faq__avXvj--h9Yom";
export var footer = "PlasmicPricing-module--footer--zhosD";