// extracted by mini-css-extract-plugin
export var root = "PlasmicHomeCta-module--root--2Am2m";
export var box__yp28G = "PlasmicHomeCta-module--box__yp28G--1loVF";
export var box__lzxGr = "PlasmicHomeCta-module--box__lzxGr--1iMth";
export var box__r2F2B = "PlasmicHomeCta-module--box__r2F2B--3GmCG";
export var box__ljV1S = "PlasmicHomeCta-module--box__ljV1S--1AMG7";
export var textbox = "PlasmicHomeCta-module--textbox--1pm9S";
export var linkButton = "PlasmicHomeCta-module--linkButton--2rfYO";
export var box__i0Usi = "PlasmicHomeCta-module--box__i0Usi--1P-zN";
export var box__xkrMl = "PlasmicHomeCta-module--box__xkrMl--3EC9u";
export var svg = "PlasmicHomeCta-module--svg--1sl35";